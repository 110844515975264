@import url(https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,400;0,600;0,700;1,700&family=Lato:wght@400;700&display=swap);
/* use border-box by default, globally */
*, *:before, *:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  /* remove the tiny space around the edge of the page */
  margin: 0;

  /* extend body to full viewport height */
  min-height: 100vh;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  /* limit width of images to their containers */
  max-width: 100%;

  /* autoscale images based on width, preserving aspect ratio */
  height: auto;

  /* prevent small gap below images */
  vertical-align: bottom;
}

/* no default UA margin-top for text elements */
h1, h2, h3, h4, h5, h6, p {
   margin-top: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* no list-markers by default, since lists are used more often for semantics */
ul, ol {
  list-style: none;
}

/* no underline on link text, normalize default link color */
a {
  color: blue;
  text-decoration: none;
}

/* remove/improve UA button defaults, normalize across vendors */
button, .button {
  border: 0;
  border-radius: 0.25rem;
  font-family: system-ui, sans-serif;
  font-size: 1rem;
  line-height: 1.2;
  white-space: nowrap;
  text-decoration: none;
  padding: 0.25rem 0.5rem;
  margin: 0.25rem;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

button:hover, button:focus {
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}

button:active {
  transform: scale(0.99);
}

@font-face {
  font-family: 'Army';
  src: local('Army'), url(/static/media/Army.1224eeac.otf) format('opentype');
}

/* Helpers */

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.col {
  display: flex;
  flex-direction: column;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fill {
  flex: 1 1;
  align-self: stretch;
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.card {
  background: white;
  padding: 3rem;
}

.text-green {
  color: #3FA14F;
}

.text-yellow {
  color: #C3C71E;
}

/* Components */

.MainLayout {
  min-height: 400px;
  height: 100vh;
}

.Modal {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
}

.Modal.open {
  display: block;
}

.Modal-content {
  position: absolute;
  max-width: 600px;
  width: 80vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem 1.2rem;
  background: #fff;
}

@media screen and (min-width: 768px) {
  .Modal-content {
    padding: 1rem 3rem;
  }
}

.NavBar {
  background: #171717;
  min-height: 65px;
  flex: 0 0;
  display: flex;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.NavBar .row {
  justify-content: center;
}

.NavBar-logo {
  vertical-align: middle;
  padding: 0.3rem 0;
  border: 3px solid #171717;
  margin: 0.5rem 1.5rem;
  font-size: 36px;
  font-family: 'Army', sans-serif;
}

.NavBar-logo:hover {
  border-bottom: 3px solid #C3C71E;
}

.NavBar-notice {
  display: none;
}

@media screen and (min-width: 600px) {
  .NavBar-logo {
    font-size: 40px;
  }
}

@media screen and (min-width: 768px) {
  .NavBar .row {
    justify-content: flex-start;
  }

  .NavBar-logo {
    font-size: 48px;
  }

  .NavBar-notice {
    display: block;
    text-transform: uppercase;
    color: #aabbaa;
    font-family: Lato, sans-serif;
    font-size: 18px;
  }
}

.LandingPage-header {
  position: relative;
  background-color: #242424;
  background:
    linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ),
    url(/static/media/hacker-resized.444181a7.jpg) no-repeat center center scroll;
  background-size: cover;
  background-position: 65% 50%;
  flex: 1 1;
  min-height: 400px;
}

@media screen and (min-width: 768px) {
  .LandingPage-header {
    background-position: center center;
  }
}

.darken-cover {
  background: linear-gradient(to left, rgba(0,0,0,0), rgba(0,0,0,1));
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 60%;
}

.hero-section {
  position: absolute;
  color: #eee;
  margin: 0 3vw;
  width: 300px;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hero-section .hero-text {
  text-transform: uppercase;
  text-align: center;
  font-family: 'Bai Jamjuree', sans-serif;
  color: #C4C4C4;
  margin-bottom: 16px;
  line-height: 38px;
  font-size: 30px;
  font-style: italic;
  font-weight: bold;
  text-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
}

.hero-section .cta {
  position: relative;
  bottom: 0;
  background: #C3C71E;
  box-shadow:
    0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 4px 8px rgba(255, 255, 255, 0.5);
  border-radius: 6px;
  padding: 0.6rem 1.5rem;
  font-family: Lato, sans-serif;
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 26px;
  text-align: center;
  align-self: center;
}

.hero-section .cta .extra-text {
  display: none;
}

.hero-section .cta:hover {
  bottom: 6px;
  box-shadow:
    0px 10px 8px rgba(0, 0, 0, 0.5),
    inset 0px 4px 8px rgba(255, 255, 255, 0.5);
  transition: bottom 0.2s, box-shadow 0.2s;
}

@media screen and (min-width: 600px) {
  .hero-section {
    top: 35%;
    left: 4%;
    transform: none;
  }
}

@media screen and (min-width: 768px) {
  .hero-section {
    width: 420px;
  }

  .hero-section .hero-text {
    line-height: 52px;
    font-size: 42px;
    margin-bottom: 24px;
  }

  .hero-section .cta {
    font-size: 1.35rem;
    padding: 1rem 2rem;
  }

  .hero-section .cta .extra-text {
    display: inline;
  }
}

.ThankYouPage {
  background: #c4c4c4;
}

.ThankYouPage h1 {
  text-align: center;
  font-size: 1.6rem;
}

@media screen and (min-width: 768px) {
  .ThankYouPage h1 {
    font-size: 2rem;
  }
}

/* Mailchimp embedded form styles */

#mc_embed_signup h2 {
  font-size: 1.2rem;
  text-align: center;
  font-weight: 500;
}

#mc_embed_signup .early-access {
  font-size: 0.9rem;
  font-style: italic;
  text-align: center;
  padding: 0 1.2rem;
  margin: 1.2rem 0;
  line-height: 1.4;
}

@media screen and (min-width: 768px) {
  #mc_embed_signup h2 {
    font-size: 1.8rem;
  }
  #mc_embed_signup .early-access {
    font-size: 1.1rem;
  }
}

#mc_embed_signup .mc-field-group label {
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 8px;
}

#mc_embed_signup #mc-embedded-subscribe {
  display: block;
  font-size: 1rem;
  margin: 10px auto;
  padding: 6px 30px;
  height: auto;
}

