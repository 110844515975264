/* use border-box by default, globally */
*, *:before, *:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  /* remove the tiny space around the edge of the page */
  margin: 0;

  /* extend body to full viewport height */
  min-height: 100vh;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  /* limit width of images to their containers */
  max-width: 100%;

  /* autoscale images based on width, preserving aspect ratio */
  height: auto;

  /* prevent small gap below images */
  vertical-align: bottom;
}

/* no default UA margin-top for text elements */
h1, h2, h3, h4, h5, h6, p {
   margin-top: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* no list-markers by default, since lists are used more often for semantics */
ul, ol {
  list-style: none;
}

/* no underline on link text, normalize default link color */
a {
  color: blue;
  text-decoration: none;
}

/* remove/improve UA button defaults, normalize across vendors */
button, .button {
  border: 0;
  border-radius: 0.25rem;
  font-family: system-ui, sans-serif;
  font-size: 1rem;
  line-height: 1.2;
  white-space: nowrap;
  text-decoration: none;
  padding: 0.25rem 0.5rem;
  margin: 0.25rem;
  cursor: pointer;
  user-select: none;
}

button:hover, button:focus {
  filter: brightness(110%);
}

button:active {
  transform: scale(0.99);
}
